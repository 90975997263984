<template>
  <overlay-scrollbars class="page page-softwareNet" ref="os">
    <div class="box box__addService">
      <div class="header"></div>
      <div class="content">
        <div class="block">
          <div class="hdr">
            <div class="ttl">Additional services</div>
            <div class="btn btn__create" @click="openCreateAddServiceModal">Create</div>
          </div>
          <Table
            :options="addServicesTableData"
            @editItem="editAddService"
            @deleteItem="deleteAddService"
            @change-page="getAddServices"
            @scroll-top="toElTop"
          />
        </div>
      </div>
    </div>
  </overlay-scrollbars>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Table from '@/components/elem/Table'

export default {
  name: 'SoftwareNet',
  components: {
    Table
  },
  data: () => ({}),
  computed: {
    ...mapState({
      addServicesTableData: state => state.software_net.addServicesTableData,
    }),
  },
  created () {
    this.getAddServices()
  },
  methods: {
    ...mapActions({
      getAddServices: 'software_net/getAddServices',
    }),
    openCreateAddServiceModal () {
      this.$store.commit('setModal', {
        template: 'create-software-add-service'
      })
    },
    editAddService (obj) {
      const item = this.addServicesTableData.paginationData.data.find(el => +el.id === obj.id)

      this.$store.commit('setModal', {
        template: 'create-software-add-service',
        options: {
          id: obj.id,
          form: {
            service_name: item.service_name,
            country_id: item.country ? item.country.id : null,
            service_type: item.service_type,
            price: item.price,
            service_time: item.service_time,
            description: item.description
          }
        }
      })
    },
    deleteAddService (obj) {
      this.$store.commit('setModal', {
        template: 'delete',
        options: {
          id: obj.id,
          actionType: 'delete',
          table: 'software-add-services',
          type: 'software additional service',
          url: '',
          successfullMessage: 'You successfully deleted software additional service',
        }
      })
    },
    toElTop (el) {
      this.$refs.os.osInstance().scroll({ el: el, margin: 10 }, 1500)
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    &__addService {
      .content {
        padding: 0 0 1rem;

        .block {
          margin-bottom: 1.5rem;

          .hdr {
            display: flex;
            align-items: center;
            margin-left: .75rem;
            margin-bottom: .5rem;

            svg.img {
              width: 1.75rem;
              height: 1.75rem;
              margin-right: .5rem;
            }

            .ttl {
              padding: .25rem .5rem;
            }

            .btn {
              border-radius: .5rem;
              padding: .5rem 1.5rem;
              margin-left: 1.75rem;
              background-color: var(--c_primary);
            }
          }
        }
      }
    }
  }
}
</style>